<!-- <template>
  <div class="Publish">
    <div class="float">
      <Icon class="close" icon="mingcute:close-line" @click="router.back()" />
      <div style="margin-top:200px">
      <h2>请先选择视频所属领域，再选择文件</h2>

      <select id="tag" name="tag">
        <option value="0">请选择领域</option>
        <option v-for="(tag, idx) in tags" :key="idx" :value="tag">{{ tag }}</option>
      </select>

      <input type="file" @change="onFileChange" accept="video/mp4,video/x-m4v,video/*" />
      <button @click="uploadFile">上传</button>

      <div class="progress" style="width: 50%;height: 20px;margin-top: 20px;background-color: cornsilk;">
        <div id="bar" class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
            aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 0%;background-color:blue;color:yellow"></div>
      </div>
    </div>
    </div>
  </div>
</template> -->

<template>
  <div class="Publish">
    <div class="float">
      <Icon class="close" icon="mingcute:close-line" @click="this.$router.back()" />
      <div class="content" style="margin-top: 200px;">
        <h2>请先选择视频所属领域，再选择文件</h2>
        <div style="margin-left: 40px;">
          <div class="form-group">
            <label for="tag">选择领域:</label>
            <select id="tag" name="tag">
              <option value="0">请选择领域</option>
              <option v-for="(tag, idx) in tags" :key="idx" :value="tag">{{ tag }}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="file">选择文件:</label>
            <input type="file" id="file" @input="onFileChange" accept="video/mp4,video/x-m4v,video/*" />
          </div>

          <!-- <button @click="uploadFile" class="upload-button">上传</button> -->

          <div class="progress">
            <div id="bar" class="progress-bar" :style="{ width: uploadProgress + '%' }">{{ uploadProgress }}%</div>
          </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue'
import { videoTags } from '@/api/videos'
import { useBaseStore } from '@/store/pinia';
import * as tus from 'tus-js-client';
import { useRouter } from 'vue-router'
import { _notice } from '@/utils'



export default {
  data() {
    return {
      file: null,
      uploadProgress: 0,
      tags: [],
    };
  },

  computed: {
    fetchTags() {
      return async () => {
        let res = await videoTags()
        this.tags
        .unshift(...res.data)
       // .push(...res.data)
        
      }
    },
  },
  methods: {
    onFileChange(event) {
      this.file = event.target.files[0];
      if (!this.file) {
        alert("请先选择一个文件");
        return;
      }

      // <select id="tag" name="tag" class="crayons-select w-10">
      //   <option value="0">请选择领域</option>
      //   <option v-for="(tag, idx) in tags" :key="idx" :value="tag">{{ tag }}</option>
      // </select>
      var tag = document.getElementById('tag').value
      const file = this.file

      const store = useBaseStore()
      const upload = new tus.Upload(file, {
      endpoint: 'https://api.cloudflare.com/client/v4/accounts/35cf7d9927f1f3e9dc21ade22101de6d/stream',
      headers: {
        Authorization: 'Bearer 1tVtSeinii1g_0gAUDK2EqN5Tt4B1mO5Njcqrsl7'
      },
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type
      },

      uploadSize: file.size,
      onError: function (error) {
        console.log("Failed because: " + error)
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
        const progress = document.getElementById("bar")
        progress.style.width = percentage + "%"
        progress.innerHTML = percentage + "%";
      },
      onSuccess: function () {
        fetch(upload.url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer 1tVtSeinii1g_0gAUDK2EqN5Tt4B1mO5Njcqrsl7'
          },
          allow_origin: '*',
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          fetch('/videos', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            // 将文件信息传给后端
            body: JSON.stringify({
              hls: data.result.playback.hls,
              dash: data.result.playback.dash,
              thumbnail: data.result.thumbnail,
              stream_media_id: data.result.stream_media_id,
              filename: data.result.filename,
              filesize: data.result.filesize,
              preview: data.result.preview,
              uid: data.result.uid,
              video: upload.url,
              user_id: store.userinfo.userId,
              tag: tag,
            }),
          }).then(function (response) {
            // return response.json();
          }).then(function (data) {
            console.log(data);
            window.location.href = '/home'
            alert('上传成功')
          });
        });
      },
      // onAfterResponse: function (req, res) {
      //     debugger
      //     return new Promise(resolve => {
      //         var mediaIdHeader = res.getHeader('stream-media-id');
      //         if (mediaIdHeader) {
      //             mediaId = mediaIdHeader;
      //         }
      //         resolve();
      //     });
      // },
    });

      upload.start();
    }
  },
  mounted() {
    const store = useBaseStore()
    if (store.userToken === '') {
      _notice("请登录")
      this.$router.push('/login')
    }
  this.fetchTags()
  },
};





function getUserMedia(constrains, success, error) {
  if (navigator.mediaDevices.getUserMedia) {
    //最新标准API
    navigator.mediaDevices.getUserMedia(constrains).then(success).catch(error)
  } else if (navigator.webkitGetUserMedia) {
    //webkit内核浏览器
    navigator.webkitGetUserMedia(constrains).then(success).catch(error)
  } else if (navigator.mozGetUserMedia) {
    //Firefox浏览器
    // eslint-disable-next-line no-undef
    navigator.mozGetUserMedia(constrains).then(success).catch(error)
  } else if (navigator.getUserMedia) {
    //旧版API
    navigator.getUserMedia(constrains).then(success).catch(error)
  }
}

function getMedia() {
  // let constraints = {video: {width: this.bodyWidth, height: this.bodyHeight - 60}, audio: false};
  // let constraints = {video:{width:480,height:320}, audio: false};
  let constraints = { video: true, audio: false }
  try {
    getUserMedia(
      constraints,
      (MediaStream) => {
        videoEl.value.srcObject = MediaStream
        videoEl.value.play()
      },
      function (PermissionDeniedError) {
        console.log(PermissionDeniedError)
      }
    )
  } catch (e) {
    console.log('e', e)
  }
}

onMounted(() => {
  videoEl.value = document.getElementById('video')
  videoTags().then((res) => {
    tags.value = res.data
  })
  getMedia()
})
</script>

<style scoped lang="less">
@import '../../assets/less/index';

.Publish {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: auto;
  color: white;
  background: black;

  .footer {
    font-size: 15rem;
    font-weight: bold;
    color: var(--second-text-color);

    .base-slide-item {
      display: flex;
      align-items: center;
    }

    .active {
      color: white;
    }
  }

  .float {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: calc(100% - 60px);

    .close {
      font-size: 28rem;
      position: absolute;
      left: 20rem;
      top: 20rem;
    }

    .choose-music {
      position: absolute;
      left: 50%;
      top: 20rem;
      transform: translateX(-50%);
      border-radius: 20rem;
      background: #333333;
      padding: 5rem 15rem;
      display: flex;
      align-items: center;
      font-size: 14rem;

      svg {
        font-size: 30rem;
        margin-right: 5rem;
        width: 12rem;
        height: 12rem;
      }
    }

    .toolbar {
      position: absolute;
      top: 20rem;
      right: 10rem;

      .tool {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20rem;
        font-size: 26rem;
      }
    }
  }
}


form.upload-form {
    background: #fdf9f3;
    background: var(--theme-container-accent-background, #fdf9f3);
    max-width: 590px;
    margin: 20px auto;
    padding: 40px 10px;
    border: 2px solid #4e57ef;
    box-shadow: 4px 5px 0px #4e57ef;
  }
</style>





<!-- <script>
import { useStore } from '@store/pinia'




</script> -->